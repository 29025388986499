import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CreateDocumentRequest } from '../models/cnet-create-document-request';
import { Download, DownloadService } from './download.service';
import { LoaderService } from './shared/loader.service';

@Injectable({
  providedIn: 'root'
})
export class CreateDocumentOnNewStructureService {

  constructor(
    private downloadService: DownloadService,
    private loaderService: LoaderService,
    private router: Router
  ) { }

  public downloadDynamicDocument(contractDocumentCode, type, language): Observable<Download> {
    let body: CreateDocumentRequest = {
      documentTypeInput: {
        type: type,
        language: language
      },
      documentCode: contractDocumentCode,
      forceManualSignature: true
    }

    this.loaderService.show();

    if (body) {
      return this.downloadService.downloadAsPost(`${environment.apiUrl}/document-create/dynamically`, body)
        .pipe(
          finalize(() => this.loaderService.hide()),
        )
    }
    return of(null);
  }

  public openOnNewTab(contractDocumentCode, isElegibleToBePrinted, type, language, saveDocument): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/document`], {
        queryParams: {
          contractDocumentCode,
          isElegibleToBePrinted,
          type,
          language,
          saveDocument
        }
      })
    );

    window.open(url, '_blank');
  }
}

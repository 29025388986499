import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, catchError, map, merge, of, retry, switchMap, takeUntil, tap, throwError, timer, toArray } from 'rxjs';
import { StartupService } from 'src/app/core/services/startup.service';
import { SharedModule } from '../../shared.module';
import { LanguageSwitcherModule } from '../../components/language-switcher/language-switcher.module';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-offline',
  standalone: true,
  imports: [CommonModule, SharedModule, LanguageSwitcherModule],
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss']
})
export class OfflineComponent implements OnInit, OnDestroy {

  startup = inject(StartupService)
  router = inject(Router)
  destroyRef = inject(DestroyRef)
  private readonly _destroyed = new Subject<void>();
  date = new Date();
  private timer = 40000

  isBackonline$ = this.startup.online$.pipe(
    switchMap(isOnline => {

      if (isOnline) {
        return this.startup.requestSpa()
      }
      else {
        return of(false)
      }
    }),
  )

  isBackOnline2 = this.startup.requestSpa().pipe(
    map(isOnline => {
      if (!isOnline) {
        throw new Error('not online')
      }
      return isOnline
    })
  )


  ngOnInit(): void {

    this.isBackonline$.pipe(
      takeUntil(this._destroyed),
      map((isOnline) => {
        if (isOnline) {
          const lastRoute = localStorage.getItem('lastRouteBeforeError')
          if (lastRoute) {
            localStorage.removeItem('lastRouteBeforeError')
          }
          this.router.navigate([lastRoute ?? '']);
          return true;
        }
      })
    ).subscribe();

    setTimeout(() => {
      this.isBackOnline2.pipe(
        takeUntil(this._destroyed),

        map((isOnline) => {
          if (isOnline) {
            const lastRoute = localStorage.getItem('lastRouteBeforeError')
            if (lastRoute) {
              localStorage.removeItem('lastRouteBeforeError')
            }
            this.router.navigate([lastRoute ?? '']);
            return true;
          }
        }),

        catchError((err) => {
          return err
        }),
        retry({
          delay: (error) => {
            return timer(this.timer);
          }
        }),
      ).subscribe();
    }, this.timer);

  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { enumLoginTokenAction } from '../enums/enumLoginTokenAction';
import { enumReturnUrl } from '../enums/enumReturnUrl';
import { enumTranslateRolesInRoutes } from '../enums/enumTranslateRolesInRoutes';
import { Role } from '../enums/role';
import { AuthService } from './auth.service';
import { StartupService } from './startup.service';

@Injectable({
  providedIn: 'root'
})
export class MainPageService {

  constructor(
    private _authService: AuthService,
    private _startupService: StartupService,
    private _router: Router
  ) { }

  public getReturnUrl() {
    const { user_role, permissions } = this._authService.getUser();
    if (permissions) {

      switch (user_role) {
        case Role.Student:
          return this.getCandidateReturnUrl(permissions);
          break;
        case Role.CompanyAdmin:
          return this.getCompanyReturnUrl(permissions);
          break;
        case Role.InstitutionAdmin:
          return this.getInstitutionAdminReturnUrl(permissions);
          break;
        case Role.CampusAdmin:
          return this.getCampusAdminReturnUrl(permissions);
          break;
        case Role.Faculty:
          return this.getInstitutionAdvisorReturnUrl(permissions);
          break;
        case Role.CnetAdmin:
          return this.getCnetAdminReturnUrl(permissions);
          break;
      }
    }
    return 'unauthorized';
  }

  public getSiaReturnUrl(action: enumLoginTokenAction, token: string): string {
    switch (action) {
      case enumLoginTokenAction.mostraEstagio:
      case enumLoginTokenAction.cadastraEstagioObrigatorio:
      case enumLoginTokenAction.cadastraEstagioNaoObrigatorio:
        return `${enumReturnUrl.CandidateInternshipsCustom}/${token}`;
      case enumLoginTokenAction.painel:
      default:
        return this.getReturnUrl();
    }

  }

  public navigateToLoginUrl() {
    this.navigateToUrl('/auth');
  }

  public navigateToLoginCompanyUrl() {
    this.navigateToUrl('/auth/employers');
  }

  private navigateToUrl(url: string) {
    const symplicityUrl = this._startupService.getSymplicityUrl();
    if (symplicityUrl == null || symplicityUrl === '') {
      this._router.navigate([url]);
    } else {
      window.open(symplicityUrl, '_self');
    }
  }

  private getCandidateReturnUrl(permissions: string[]) {
    if (permissions.includes("OpportunityModuleAccess")) {
      return enumReturnUrl.CandidatePanel;
    }
    if (permissions.includes("InternshipModuleAccess")) {
      return enumReturnUrl.CandidateInternships;
    }
    return 'unauthorized';
  }

  private getCompanyReturnUrl(permissions: string[]) {
    if (permissions.includes("OpportunityModuleAccess")) {
      return enumReturnUrl.CompanyPanel;
    }
    if (permissions.includes("InternshipModuleAccess")) {
      return enumReturnUrl.CompanyInternships;
    }
    return 'unauthorized';

  }

  private getInstitutionAdminReturnUrl(permissions: string[]) {
    if (permissions.includes("InternshipModuleAccess")) {
      return enumReturnUrl.InstitutionAdminInternships;
    }
    if (permissions.includes("OpportunityModuleAccess")) {
      return enumReturnUrl.InstitutionAdminPanel;
    }
    return 'unauthorized';

  }

  private getCampusAdminReturnUrl(permissions: string[]) {
    if (permissions.includes("InternshipModuleAccess")) {
      return enumReturnUrl.InstitutionCampusAdminInternships;
    }
    return 'unauthorized';
  }


  private getInstitutionAdvisorReturnUrl(permissions: string[]) {
    return enumReturnUrl.InstitutionAdvisorPanel;
  }

  private getCnetAdminReturnUrl(permissions: string[]) {
    if (permissions?.includes("ContratanetAdminAccess") && this._startupService.isPortalContratanet()) {
      return enumReturnUrl.admin;
    }
    return enumReturnUrl.PortalConfigurations;
  }


  navigateToReturnUrl() {
    const urlToRedirect = this.getReturnUrl();
    this._router.navigate([urlToRedirect]);
  }
}

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { StartupService } from 'src/app/core/services/startup.service';

export const offlineGuard: CanActivateFn = (route, state) => {
  const router = inject(Router)
  const startup = inject(StartupService)
  const lastRoute = localStorage.getItem('lastRouteBeforeError')

  return startup.isOnline() ?

    startup.requestSpa().pipe(map(resp => {
      if (resp) {
        router.navigate([lastRoute ?? ''])
      }
      return !resp
    }))
    : true;
};

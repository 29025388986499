import { animate, query, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnDestroy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { interval, map, take, tap } from 'rxjs';
import { CreateDocumentOnNewStructureService } from 'src/app/core/services/create-document-on-new-structure.service';
import { enumDownloadState } from 'src/app/core/services/download.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-new-tab-helper',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './new-tab-helper.component.html',
  styleUrls: ['./new-tab-helper.component.scss'],

  animations: [
    trigger('fadeInOut', [
      transition('* => *', [
        query(':enter', [
          style({ opacity: 0, scale: 0.9 }),
        ], { optional: true }),
        query(':leave', [
          animate('0.3s', style({ opacity: 0, scale: 0.9 }))
        ], { optional: true }),
        query(':enter', [
          animate('0.3s', style({ opacity: 1, scale: 1 }))
        ], { optional: true })
      ])
    ])
  ]


})
export class NewTabHelperComponent implements OnDestroy {
  createDocumentService = inject(CreateDocumentOnNewStructureService)
  route = inject(ActivatedRoute)
  params = this.route.snapshot.queryParams;

  url$ = this.createDocumentService.downloadDynamicDocument(
    this.params.contractDocumentCode,
    this.params.type,
    this.params.language).pipe(tap(() => {
      document.body.style.overflow = 'hidden';
    }));


  url = toSignal(this.url$)
  isInProgreess = computed(() => this.url().state !== enumDownloadState.DONE)
  isInPending = computed(() => this.url().state === enumDownloadState.PENDING)
  progressPercentage = computed(() => this.url().progress)
  ngOnDestroy(): void {
    document.body.style.overflow = 'auto';
  }
}

import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { OpenInOtherTabGuard } from 'src/app/core/guards/open-in-other-tab.guard';
import { LegacyRenderComponent } from '../../pages/legacy-render/legacy-render.component';

export const INSTITUTION_ADVISOR_LEGACY_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/'
  },
  {
    path: 'panel', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Painel',
      legacyRoute: 'instituicao/orientador/painel.aspx',
      roles: ['Orientador'],
      permissions: {
        only: 'InternshipModuleAccess',
        redirectTo: '/unauthorized'
      },
      icon: 'home'
    },
  },
  {
    path: 'internships/view', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Estágios',
      legacyRoute: 'estagios/visualizar.aspx',
      roles: ['Orientador'],
      permissions: {
        only: 'InternshipModuleAccess',
        redirectTo: '/unauthorized'
      },
      hideMenu: true,
      icon: 'graduation-cap',
      notInHeaderMenu: true,
    },
  },
  {
    path: 'change-password', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Alterar senha',
      legacyRoute: 'comuns/trocasenhausuario.aspx',
      roles: ['Orientador'],
      permissions: {
        only: 'PasswordChange',
        redirectTo: '/unauthorized'
      },
      profileMenuItens: true,
      profileMenuItenOrder: 1
    },
  },
  {
    path: 'digital-password', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Senha digital',
      legacyRoute: 'configuracoes/conta/senha-digital',
      roles: ['Orientador'],
      permissions: {
        only: 'DigitalSignaturePasswordChange',
        redirectTo: '/unauthorized'
      },
      profileMenuItens: true,
      profileMenuItenOrder: 2
    },
  },
  {
    path: 'personal-data', component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      name: 'Dados Pessoais',
      legacyRoute: 'comuns/dadosusuario.aspx',
      roles: ['Orientador'],
      permissions: {
        redirectTo: '/unauthorized'
      },
      profileMenuItens: true,
      profileMenuItenOrder: 3
    },
  },
  {
    path: 'support', component: LegacyRenderComponent,
    canActivate: [AuthGuard, OpenInOtherTabGuard, NgxPermissionsGuard],
    data: {
      name: 'Central de Suporte',
      legacyRoute: 'atendimento/m/acesso',
      roles: ['Orientador'],
      permissions: {
        only: 'ZenDeskTicketRegister',
        redirectTo: '/unauthorized'
      },
      profileMenuItens: true,
      profileMenuItenOrder: 4
    },
  },
  {
    path: 'privacy-policy',
    component: LegacyRenderComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      externalUrl: `https://www.symplicity.com/support/privacy-policy-pt`,
      name: 'Política de Privacidade',
      roles: ['Orientador'],
      permissions: {
        only: 'PrivacyPolicyVisualize',
        redirectTo: '/unauthorized'
      },
      profileMenuItens: true,
      profileMenuItenOrder: 6
    },
  },
  {
    path: 'document',
    component: LegacyRenderComponent,
    data: {
      name: 'Documento',
      legacyRoute: 'estagio/documento',
      notInHeaderMenu: true,
      hideHeader: true,
      hideFooter: true
    },
  },
  {
    path: 'document/:id',
    component: LegacyRenderComponent,
    data: {
      name: 'Documento',
      legacyRoute: 'estagio/documento',
      notInHeaderMenu: true,
      hideHeader: true,
      hideFooter: true
    },
  },
  {
    path: 'document/:system/:type/:name/:id',
    component: LegacyRenderComponent,
    data: {
      name: 'Documento',
      legacyRoute: 'estagios/documentos',
      notInHeaderMenu: true,
      hideHeader: true,
      hideFooter: true
    },
  },
  {
    path: 'monitoring-document',
    component: LegacyRenderComponent,
    data: {
      name: 'Documento',
      legacyRoute: 'documentacao/templateacompanhamento.aspx',
      notInHeaderMenu: true,
      hideHeader: true,
      hideFooter: true
    },
  },
  {
    path: 'internship/invalidate-contract/:id', component: LegacyRenderComponent,
    canActivate: [AuthGuard,NgxPermissionsGuard],
    data: {
      notInHeaderMenu: true,
      name: 'Invalidar Contrato',
      legacyRoute: 'm/estagio/contrato-de-estagio/invalidar-formulario',
      roles: ['Orientador'],
      icon: 'black-tie',
      permissions: {
        only: 'InternshipContractDataValidate',
        redirectTo: '/unauthorized'
      },
    },
  },
];

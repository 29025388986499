@if(isInProgreess()){

<div class="h-100 d-flex flex-column justify-content-center align-items-center">
  <div class="d-flex flex-column justify-content-center progress-card ">

    <h2 class="mt-0">
      {{ "Aguarde enquanto geramos o documento" | translate}}
    </h2>

    <h3 class="m-0">
      @if(isInPending()){
        {{'Preparando documento' | translate}}
      }@else {

        {{"Buscando dados" | translate}}

        <span class="text m-0">
          {{
          progressPercentage() <= 33? ('do estagiário' | translate)
          : progressPercentage()> 33 && progressPercentage() <=66 ? ("da concedente" | translate)
          : ("da instituição" | translate) }} </span>
    }

      <cnet-progress-dots size="4" color="secondary" />
    </h3>

    <cnet-progress-bar color="secondary" [value]="progressPercentage()" />
  </div>
</div>

}
@else {
<app-iframe-handler [src]="url().url" [@fadeInOut] />
}
